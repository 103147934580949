export * from './app.config';

export const apiUrl = 'https://portaltest.visionslive.com';
export const vlqrApiUrl = 'https://apitest.visionslive.com/VL';
export const clientUrl = 'https://clienttest.visionslive.com';

export const releaseNotesUri = 'https://visionslive-test.canny.io/changelog';

export const growthbookApiKey = 'sdk-u4p3ptji2Swyn6C';
export const growthbookSecretKey = 'z4ZC0VzZLNYKZCfzsvVvPA==';
